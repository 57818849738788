import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../../components/Layout";
import { PageSeo } from "../../components/Seo";
import { InviteProcessSection } from "../../components/InviteProcessSection";
import { TestimonialsSection } from "../../components/TestimonialsSection";
import { Container, Heading } from "../../components/Page";
import { Copy } from "../../components/Copy";

const imageProps = {
  height: 600,
  width: 600,
  transformOptions: {
    cropFocus: "center",
  },
};

const OnTheDayPage = () => {
  return (
    <Layout>
      <PageSeo tabTitleSegments={["On The Day"]} title="On The Day" />
      <Container>
        <Heading>On The Day</Heading>
        <div className="mx-auto max-w-narrow">
          <Copy>
            <p>
              Your wedding stationery doesn't just stop at the invitations, you will need a table plan, place names, table numbers, menus, order of the day, order of service, signage and thank you cards.
              Consistency is key with your wedding theme, having your design run through each piece of wedding stationery.
            </p>
            <p>
              View the gallery below to see the different 'on the day' items that are available.
            </p>
          </Copy>
        </div>
        <div className="kgh-grid my-16 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
          <StaticImage {...imageProps} src="../../images/on-the-day/Autumn Table Plan.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-1.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/watercolour fade on the day set-18.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-4.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-8.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-09.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-10.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/Website Watercolour Placename.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-11.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/purple &amp; gold agate place cards-14.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/Eucalyptus Table Plan.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/watercolour fade on the day set-17.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-12.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-2.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-13.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-16.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/watercolour fade on the day set-59.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/Blossom Order of the Day.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-17.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-3.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-18.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/watercolour fade on the day set-15.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-19.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-20.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-5.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/On the day-21.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/purple &amp; gold agate place cards-15.jpg" alt="" />
          <StaticImage {...imageProps} src="../../images/on-the-day/Watercolour fade Table plan.jpg" alt="" />
        </div>
        <InviteProcessSection />
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

export default OnTheDayPage;
